import {withoutPostfix} from "@/lib/prefixPostfix";

const getDateStrings = () => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]
  const today = new Date()
  const date = today.getDate()
  const month = monthNames[today.getMonth()]
  const year = today.getFullYear()
  const dateString = [date, month, year].join(' ')
  return {
    date,
    month,
    year,
    accessDate: dateString
  }
}
export const getSectionAPAReference = (authors = 'No authors listed yet', name, uri) => {
  const {year, accessDate} = getDateStrings()
  return `${withoutPostfix(authors.trim(), ',')}, '${name}: an Itiner-e road section resource', Itiner-e: The Gazetteer of Ancient Roads, ${year} <${uri}> [accessed: ${accessDate}]`
}
