import {downloadJSONFile} from "@/components/lib/downloadFile";

export const downloadEntries = async (name, list) => {
  const fileName = `${name}.json`

  if (list?.length) {
    const geoJson = {
      type: 'FeatureCollection',
      features: list.map(e => ({ // https://geojson.org/
        type: e.type,
        geometry: e.geometry,
        properties: e.properties
      }))
    }

    downloadJSONFile(fileName, geoJson)
  }
}

