<template>
  <div class="sectionMap map container px-2 my-4">
    <div
        id="map"
        ref="mapEl"
    ></div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {GET_ENTRY} from "@/store/operations";
import L from "leaflet";

export default {
  name: "SectionMap",
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const mapEl = ref(null)
    const rendered = ref(false)
    const map = ref(null)
    const store = useStore()
    const section = computed(() => store.state.entries?.[props.uuid])

    const loadSection = () => new Promise((resolve, reject) => {
      let asset = store.state.entries?.[props.uuid]
      if (asset) return resolve(asset)

      store.dispatch(GET_ENTRY, props.uuid)
          .then(() =>
              resolve(store.state.entries?.[props.uuid])
          )
          .catch(reject)
    })

    const renderMap = value => {
      if (!rendered.value) {
        loadSection()
            .then(() => {

              rendered.value = true
              map.value = L
                  .map("map")

              const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              })

              osm.addTo(map.value)

              L.geoJSON(value, {
                style: function (feature) {
                  return {
                    color: feature.properties.stroke ?? '#555555',
                    weight: feature.properties['stroke-width'] ?? 2,
                    opacity: feature.properties['stroke-opacity'] ?? 1,
                  }
                },
                onEachFeature: (feature, layer) => {
                  layer.on('add', () =>
                      layer._path.setAttribute('id', feature.uuid)
                  )
                }
              })
                  .eachLayer(l => {
                    l.addTo(map.value)
                    map.value.fitBounds(l.getBounds())
                  })

              // let marker = L.marker([lat, lng],{}).addTo(mapEl);
              // map.value.flyTo([lat, lng], zoom);
            })
      }
    }

    onMounted(() => {
      loadSection()
      watch(section, value => {
        if (value?.uuid) renderMap([value])
      }, {immediate: true})
    })

    return {
      mapEl,
      section,
    }
  }
}
</script>

<style scoped>
#map {
  height: 90vh;
  width: 100%;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

body#geojsonio-body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #222;
  -webkit-font-smoothing: antialiased;
}

:focus {
  outline: 0;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  color: #199CF4;
}

p {
  padding: 10px 0;
  line-height: 200%;
}

small {
  font-size: 11px;
}

.pad0 {
  padding: 5px;
}

.pad1 {
  padding: 10px;
}

.space-bottom0 {
  margin-bottom: 5px;
}

.space-bottom1 {
  margin-bottom: 10px;
}

.space-bottom2 {
  margin-bottom: 20px;
}

.z10 {
  z-index: 10;
}

.pin-bottomleft,
.pin-bottomright {
  position: absolute;
}

.pin-bottomright {
  bottom: 0;
  right: 0;
}

.pin-bottomleft {
  bottom: 0;
  left: 0;
}

.keyline-top {
  border-top: 1px solid #ccc;
}

.keyline-right {
  border-right: 1px solid #ccc;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  display: inline-block;
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

.text-right {
  text-align: right;
}

table tr td input,
table tr th input {
  border: none;
}

table thead {
  background: #f7f7f7;
  border: 1px solid #ccc;
}

table td {
  border: 1px solid #ccc;
}

table thead tr th {
  font-weight: bold;
}

table thead tr th,
table tr th input,
table tbody tr td input {
  text-align: left;
  font: inherit;
  /*padding: 5px;*/
  /*min-width: 150px;*/
  box-sizing: border-box;
  margin: 0;
}

input[type=text] {
  font: inherit;
}

.loading:before {
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.25);
  z-index: 10;
}

.loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0px 0px -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.25;
}

.marker-properties,
.metadata {
  border-collapse: collapse;
  font-size: 11px;
  width: max-content;
  overflow: auto;
  border-bottom: 1px solid #ccc;

  /*max-height: 189px;*/
}

.marker-properties {
  display: block;
}

.metadata {
  display: table;
}

.marker-properties th {
  width: 33.3333%;
  min-width: 100px;
  white-space: nowrap;
  border: 1px solid #ccc;
}

.marker-properties.display td,
.marker-properties.display th {
  padding: 5px 10px;
}

.marker-properties tr:last-child td,
.marker-properties tr:last-child th {
  border-bottom: none;
}

.marker-properties tr:nth-child(even) th,
.marker-properties tr:nth-child(even) td,
.metadata tr:nth-child(even) td {
  background-color: #f7f7f7;
}

.geojsonio-feature .leaflet-popup-content-wrapper {
  float: left;
  width: 100%;
  padding: 0;
}

.geojsonio-feature .leaflet-popup-content {
  float: left;
  padding: 0 !important;
  width: 100%;
}

.geojsonio-feature .leaflet-popup-tip-container {
  float: left;
  margin-left: 50%;
  right: 10px;
}

datalist {
  overflow: auto;
  /*height: 150px;*/
}

.tab .tab-toggle {
  background: #eee;
  cursor: pointer;
}

.tab .tab-toggle:hover {
  background: #f7f7f7;
}

.tab .hide {
  display: none;
}

.tab .content {
  display: none;
  background: white;
  overflow: auto;
}

.tab [type=radio]:checked ~ label {
  background: white;
  border-top-width: 0;
  z-index: 2;
}

.tab [type=radio]:checked ~ label ~ .content {
  z-index: 1;
  display: block;
}

.add-row-button {
  color: #2980b9;
}

.add-row-button:hover {
  cursor: pointer;
  color: #199CF4;
}


.pill button:first-child {
  border-radius: 3px 0 0 3px;
}

.pill button:last-child {
  border-radius: 0 3px 3px 0;
}

</style>
