<template>
  <div class="roadSection p-8">
    <div
        v-if="section"
        class="section"
    >
      <h1 class="mb-8">{{ section.properties.Name }}</h1>
      <div class="properties">
        <SectionMap
            class="map float-right"
            style="max-width: 60%"
            :uuid="id"
        />

        <div class="flex items-center">
          <span class="label font-bold">Canonical URI for this page</span>
          <button
              class="mx-2 h-8"
              aria-controls="flashMessage1"
              @click="onCopyURI"
          >
            <img
                class="h-6"
                title="copy canonical URI"
                src="clipboard.jpg"
                alt="clipboard"
            />
          </button>
        </div>
        <div class="mt-1 mb-6">
          {{ section.properties.uri }}
        </div>

        <div
            class="property"
            v-for="(value, key) in properties"
            :key="key"
        >
          <span class="label font-bold">{{ key }}</span><br>
          <div class="mt-2 mb-6">{{ value }}</div>
        </div>

        <div class="flex items-center">
          <span class="label font-bold">Suggested citation</span>
          <button
              class="mx-2 h-8"
              aria-controls="flashMessage1"
              @click="onCopyReference"
          >
            <img
                class="h-6"
                title="copy APA reference"
                src="clipboard.jpg"
                alt="clipboard"
            />
          </button>
        </div>
        <div class="mt-2 mb-6">{{ apaReference }}</div>

      </div>

      <div
          ref="flashMessage"
          class="flash-message js-flash-message"
          role="status"
          id="flashMessage1"
          data-duration="2000"
      >
        <div>Copied to clipboard:
          <pre>{{ copiedText }}</pre>
        </div>
      </div>

      <button
          class="rounded px-6 py-2 bg-blue-900 text-white"
          @click="onDownload"
      >
        DOWNLOAD THIS SECTION
      </button>

    </div>
    <p v-else>
      loading...
    </p>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {getMutableFieldNames} from "@/lib/fieldNames";
import {getSectionAPAReference} from "@/lib/getSectionAPAReference";
import {useFlashMessages} from "@/views/lib/FlashMessage";
import {downloadEntries} from "@/lib/downloadEntries";
import {GET_ENTRY} from "@/store/operations";
import SectionMap from "@/components/SectionMap";

export default {
  name: "RoadSection",
  components: {
    SectionMap,
  },
  props: {
    id: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const flashMessage = ref(null)
    const copiedText = ref('')
    const store = useStore()
    const section = computed(() => store.state.entries?.[props.id])

    const labels = {
      Descriptio: 'Description',
      OBJECTID: 'Object id',
      Route_Type: 'Route type',
      Lower_Date: 'Lower date',
      Low_Date_E: 'Lower date end',
      Upper_Date: 'Upper date',
      Up_Date_E: 'Upper date end',
      Cons_per_e: 'Emperor',
      Segment_s: 'Certainty',
      Bibliograp: 'Bibliography',
    }

    const propertyNames = getMutableFieldNames()
        .filter(n => n !== 'Name')

    const properties = computed(() =>
        Object.fromEntries(
            Object.keys(section.value.properties)
                .filter(k => propertyNames.includes(k))
                .map(key => {
                  const value = section.value.properties[key]
                  return [labels?.[key] ?? key, value]
                })
        )
    )

    const apaReference = computed(() => {
      const {Authors, Name, uri} = section.value.properties
      return getSectionAPAReference(Authors, Name, uri)
    })

    const copy = text => {
      copiedText.value = text
      navigator.clipboard.writeText(text)
    }

    const loadSection = () => {
      if (!section.value) store.dispatch(GET_ENTRY, props.id)
    }

    onMounted(() => {
      useFlashMessages()
      loadSection()
    })

    const onCopyReference = () => copy(apaReference.value)
    const onCopyURI = () => copy(section.value.properties.uri)
    const onDownload = () => {
      const {uuid} = section.value
      const sections = [store.state.entries[uuid]]
      downloadEntries(uuid, sections)
    }
    return {
      apaReference,
      copiedText,
      flashMessage,
      labels,
      onCopyReference,
      onCopyURI,
      onDownload,
      properties,
      section,
      state: store.state,
    }
  }
}
</script>

<style scoped>
.flash-message {
  position: fixed;
  @apply bottom-5 lg:bottom-8;
  @apply left-5 lg:left-8;
  @apply z-10;
  @apply bg-green-100;
  @apply p-2 lg:p-3;
  @apply rounded;
  @apply text-sm lg:text-base;
  transition: 0.2s;
  -webkit-transform: translateY(16px);
  transform: translateY(16px);
  transform-origin: bottom left;
  transform-origin: bottom left;
  opacity: 0;
  visibility: hidden;
}

.flash-message--is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
</style>
